import { FilterMapping } from '@admin/services/apiDataSetVersionService';
import ApiDataSetMappableFilterColumnOptionsModal from '@admin/pages/release/data/components/ApiDataSetMappableFilterColumnOptionsModal';
import Tag from '@common/components/Tag';
import VisuallyHidden from '@common/components/VisuallyHidden';
import { Dictionary } from '@common/types';
import React from 'react';

interface Props {
  mappableFilters: Dictionary<FilterMapping>;
}

export default function ApiDataSetMappableFilterColumnsTable({
  mappableFilters,
}: Props) {
  return (
    <table
      className="dfe-table--row-highlights"
      id="mappable-filter-columns-table"
      data-testid="mappable-filter-columns-table"
    >
      <caption className="govuk-visually-hidden">
        Table showing filter columns not found in the new data set
      </caption>
      <thead>
        <tr>
          <th className="govuk-!-width-one-third">Current data set</th>
          <th className="govuk-!-width-one-third">New data set</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(mappableFilters).map(([key, filter]) => {
          return (
            <tr key={`column-${key}`}>
              <td>
                {filter.source.label}
                <br />
                <VisuallyHidden>Column: </VisuallyHidden>
                <code>{key}</code>
                <br />
                <ApiDataSetMappableFilterColumnOptionsModal
                  column={key}
                  label={filter.source.label}
                  options={Object.values(filter.optionMappings).map(
                    option => option.source.label,
                  )}
                  publicId={filter.publicId}
                />
              </td>
              <td>No mapping available</td>
              <td>
                <Tag colour="blue">Major</Tag>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
